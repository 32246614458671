import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/shop`,
});

const requestGetShopDetail = async (params) => {
  return client
    .post(`/detail`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("상품 조회에 실패하였습니다");
      console.error(err);
    });
};
const requestGetShopList = async (params) => {
  return client
    .post(`/list`, params)
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("상품 조회에 실패하였습니다");
      console.error(err);
    });
};

// 상품 수정 혹은 저장
const requestSaveShop = async (params) => {
  let form = new FormData();
  for (let i = 0; i < params.files.length; i++) {
    form.append("fileList", params.files[i]);
  }

  form.append("shop", JSON.stringify(params.shop));
  const token = localStorage.getItem("v4_token");
  return client
    .post(`/save`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("상품 저장을 실패하였습니다");
      console.error(err);
    });
};

// 상품 삭제
const requestDeleteShop = async (params) => {
  const token = localStorage.getItem("v4_token");
  return client
    .post(`/delete`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("상품 삭제를 실패하였습니다");
      console.error(err);
    });
};

const requestGetShopOrderInfo = async (params) => {
  return client
    .get(
      `/order/info?merchant_uid=${params.merchant_uid}&imp_uid=${params.imp_uid}`
    )
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("주문 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestConfirmToss = async (params) => {
  return client
    .post(
      `/order/confirm-toss?amount=${params.amount}&orderId=${params.orderId}&paymentKey=${params.paymentKey}`
    )
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("주문 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestConfirmInisis = async (params) => {
  return client
    .post(
      `/order/confirm-inisis?amount=${params.amount}&orderId=${params.orderId}&paymentKey=${params.paymentKey}`
    )
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("주문 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestConfirmInisisSave = async (params) => {
  return client
    .post(
      `/order/confirm-inisis-save?P_TID=${params.P_TID}&amount=${params.P_AMT}&idc_name=${params.idc_name}&P_REQ_URL=${params.P_REQ_URL}&P_OID=${params.P_OID}`
    )
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("주문 조회에 실패하였습니다");
      console.error(err);
    });
};

const requestInisisOrder = async (params) => {
  return client
    .post(
      `/order/inisis-order`, params
    )
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch((err) => {
      alert("이니시스 주문서 가져오기에 실패하였습니다");
      console.error(err);
    });
};

export {
  requestGetShopDetail,
  requestGetShopList,
  requestSaveShop,
  requestDeleteShop,
  requestGetShopOrderInfo,
  requestConfirmToss,
  requestConfirmInisis,
  requestInisisOrder,
  requestConfirmInisisSave,
};
