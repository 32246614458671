import {
  requestGetShopList,
  requestSaveShop,
  requestDeleteShop,
  requestGetShopDetail,
  requestGetShopOrderInfo,
  requestConfirmToss,
  requestConfirmInisis,
  requestInisisOrder,
  requestConfirmInisisSave,
} from "../apis/shop_api";

const state = {
  shopList: [],
  shopCategoryList: [],

  totalPages: 0,
  currentPage: 1,
  pageSize: 10,

  shopDetail: null,
  deliCost: 0,

  shopOrderInfo: null,
};
const getters = {
  getShopList: (state) => state.shopList,
  getShopTotalPages: (state) => state.totalPages,
  getShopPageSize: (state) => state.pageSize,
  getShopCategoryList: (state) => state.shopCategoryList,
  getShopDetail: (state) => state.shopDetail,
  getDeliCost: (state) => state.deliCost,
  getShopOrderInfo: (state) => state.shopOrderInfo,
};
const mutations = {
  SET_SHOP_LIST(state, payload) {
    state.shopList = payload.shopList || payload.funeralShopList;
    state.shopCategoryList = payload.shopCategoryList;
    state.totalPages = payload.totalPages;
    state.pageSize = payload.pageSize;
    state.deliCost = payload.deliCost;
  },
  SET_SHOP_DETAIL(state, payload) {
    state.shopDetail = payload.shopDetail;
    state.deliCost = payload.deliCost;
  },
  SET_SHOP_ORDER_INFO(state, payload) {
    state.shopOrderInfo = payload.shopOrderInfo;
  },
};
const actions = {
  async REQUEST_GET_SHOP_DETAIL({ commit }, params) {
    try {
      const data = await requestGetShopDetail(params);

      if (data?.result) {
        commit("SET_SHOP_DETAIL", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async REQUEST_GET_SHOP_LIST({ commit }, params) {
    try {
      const data = await requestGetShopList(params);

      if (data?.result) {
        commit("SET_SHOP_LIST", data.result);

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 상품 저장
  async REQUEST_SAVE_SHOP(_, params) {
    try {
      const result = await requestSaveShop(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 상품 삭제
  async REQUEST_DELETE_SHOP(_, params) {
    try {
      const result = await requestDeleteShop(params);

      if (result) {
        return result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 상품 주문서 조회
  async REQUEST_GET_SHOP_ORDER_INFO({ commit }, params) {
    try {
      const data = await requestGetShopOrderInfo(params);

      if (data?.result) {
        commit("SET_SHOP_ORDER_INFO", data.result);

        return data.result;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 토스결제 컨펌
  async REQUEST_CONFIRM_TOSS({ commit }, params) {
    try {
      const data = await requestConfirmToss(params);
      if (data?.orderInfo) {
        commit("SET_SHOP_ORDER_INFO", data.orderInfo);
      }
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 이니시스 컨펌
  async REQUEST_CONFIRM_INISIS(_, params) {
    try {
      const data = await requestConfirmInisis(params);
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 이니시스 컨펌 저장
  async REQUEST_CONFIRM_INISIS_SAVE({ commit }, params) {
    try {
      const data = await requestConfirmInisisSave(params);
      if (data?.orderInfo) {
        commit("SET_SHOP_ORDER_INFO", data.orderInfo);
      }
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  // 이니시스 주문반환
  async REQUEST_INISIS_ORDER(_, params) {
    try {
      const data = await requestInisisOrder(params);
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

export default { mutations, state, actions, getters };
